import { NextRouter } from 'next/router';
import { SubmitHandler } from 'react-hook-form';
import { IFlagsmith } from 'flagsmith/types';
import { EventsTrackerRepository } from 'hooks/events-tracker/useEventsTracker';
import { AuthStore } from '../../domain/AuthStore';
import { loginUseCase } from '../../useCases/LoginUseCase';
import { LoginCredential } from '../../domain/LoginCredential';
import { AuthRepository } from '../../domain/AuthRepository';

function useLogin({
  authRepository,
  authStore,
  eventsTracker,
  featureFlagsManager,
}: {
  authRepository: AuthRepository;
  authStore: AuthStore;
  eventsTracker: EventsTrackerRepository;
  router: NextRouter;
  featureFlagsManager: IFlagsmith;
}) {
  const onSubmit: SubmitHandler<LoginCredential> = async ({
    email,
    password,
  }) => {
    const loginCredential: LoginCredential = { email, password };
    await loginUseCase({
      loginCredential,
      authStore,
      eventsTracker,
      authRepository,
      featureFlagsManager,
    });
  };

  return {
    onSubmit,
    isLoading: authStore.isLoading,
    isAuthError: authStore.isAuthError,
  };
}

export default useLogin;
