import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { EmailField } from '@features/shared/ui/forms';
import { useNameComponent } from 'hooks';
import { useSubmitError } from '../../infra/hooks/useSubmitError';
import { useTranslation } from '../../../i18n/infra/useTranslation';
import { LoadingButton, Spacer } from '../../../shared';
import { usePasswordRules } from '../../../../src/helpers/validations/rules/usePasswordRules';
import { AuthStore } from '../../domain/AuthStore';
import { LoginCredential } from '../../domain';

interface ILoginForm {
  onSubmit: SubmitHandler<LoginCredential>;
  isLoading: boolean;
  authError: AuthStore['authError'];
  dataCy?: string;
}

function LoginForm({ onSubmit, isLoading, authError, dataCy }: ILoginForm) {
  const passwordRules = usePasswordRules();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const { onSubmitError, submitError } = useSubmitError();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
      <EmailField
        control={control}
        submitError={submitError}
        dataCy={useNameComponent(dataCy, 'email')}
      />
      <Spacer spacing={2} />
      <Controller
        name="password"
        control={control}
        rules={passwordRules}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            name="password"
            type={showPassword ? 'text' : 'password'}
            label={t('LABEL_PASSWORD')}
            value={field.value || ''}
            placeholder={t('PLACEHOLDER_PASSWORD')}
            error={
              (fieldState.isTouched || !!submitError) && !!fieldState.error
            }
            helperText={
              (!!submitError || fieldState.isTouched) &&
              (fieldState.error?.message ||
                (fieldState.error?.type === 'validate'
                  ? t('ERROR_INVALID_PASSWORD')
                  : ''))
            }
            InputProps={{
              inputProps: {
                'aria-label': 'password',
                // eslint-disable-next-line react-hooks/rules-of-hooks
                'data-cy': useNameComponent(dataCy, 'password'),
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('HELPER_PASSWORD_VISIBILITY_ICON')}
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Spacer spacing={2} />
      {authError ? (
        <>
          <Alert severity="error">{t(authError)}</Alert>
          <Spacer spacing={2} />
        </>
      ) : null}
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        isLoading={isLoading}
        dataCy={useNameComponent(dataCy, 'login-button')}
      >
        Ingresar
      </LoadingButton>
    </form>
  );
}

export default LoginForm;
