import { useEffect } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { useRouter } from 'next/router';
import { AuthStore } from '@features/auth/domain/AuthStore';
import { flagValueToPathMap } from '@features/libs/flagValueToPathMap';
import { urls } from '@features/libs/urls';

const useRedirectWhenLogged = (authStore: AuthStore) => {
  const router = useRouter();
  const featureFlagsManager = useFlagsmith();

  const { redirect } = router.query;

  const loginRedirectFlagValue = featureFlagsManager.getValue(
    'saas_login_redirect_path'
  ) as string;
  const loginRedirectPath =
    flagValueToPathMap[loginRedirectFlagValue] || urls.home;

  useEffect(() => {
    if (authStore.isAuthenticated) {
      if (redirect) {
        router.push(redirect as string);
      } else {
        router.push(loginRedirectPath);
      }
    }
  }, [authStore.isAuthenticated, router, redirect, loginRedirectPath]);
};

export default useRedirectWhenLogged;
