import { useTranslation } from '@features/i18n/infra/useTranslation';
import { TextField } from '@material-ui/core';
import { useEmailRules } from 'helpers/validations/rules/useEmailRules';
import { useMobile, useNameComponent } from 'hooks';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface IProps {
  control: Control<FieldValues, object>;
  submitError: boolean;
  dataCy?: string;
}
function EmailField({ control, submitError, dataCy }: IProps) {
  const emailRules = useEmailRules();
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Controller
      name="email"
      rules={emailRules}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          variant="outlined"
          placeholder={t('PLACEHOLDER_EMAIL')}
          label={t('LABEL_EMAIL')}
          value={field.value || ''}
          type="text"
          fullWidth
          error={(fieldState.isTouched || !!submitError) && !!fieldState.error}
          helperText={
            (!!submitError || fieldState.isTouched) &&
            (fieldState.error?.message ||
              (fieldState.error?.type === 'validate'
                ? t('ERROR_INVALID_EMAIL')
                : ''))
          }
          inputProps={{
            'aria-label': 'email',
            // eslint-disable-next-line react-hooks/rules-of-hooks
            'data-cy': useNameComponent(dataCy),
          }}
          autoFocus={!isMobile}
        />
      )}
    />
  );
}

export default EmailField;
